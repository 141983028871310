import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
import * as memorialApi from "../api/memorial";

const EditMemorial = ({ edit }) => {

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "id",
    },
    {
      name: "order",
      type: "text",
      title: "Ordem",
    },
    {
      name: "img",
      type: "dragdrop",
      title: "Logo do convênio",
      options: {
        maxFiles: 1,
      },
    },
  ];
  return (
    <EditPage
      title={"Informações do Memorial"}
      subtitle={""}
      selectedData={selectedData}
      addFunction={memorialApi.addItem}
      fetchFunction={memorialApi.fetchItem}
      editFunction={memorialApi.editItem}
      uploadFunction={memorialApi.uploadMemorial}
      edit={edit}
      url="memorial"
    />
  );
};

export default EditMemorial;
