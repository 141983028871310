//import axios from 'axios'
import axios from "./client";
import { serialize } from "@equipedigitalfloripa/ed-cms/api";
const url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3000/";
const full_url = url + "convenios_categoria/";

const options = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const fetchItems = (query) => {
  let url = full_url;
  if (query) url += "?" + serialize(query);
  return axios.get(url, options);
};

const fetchItem = (id) =>
  axios.get(full_url + id, options).then(console.log(full_url + id));

const addItem = (item) => axios.post(full_url, item, options);

const editItem = (id, item) => axios.patch(full_url + id, item, options);

const removeItem = async (id) => axios.delete(full_url + id, options);

const statusItem = (id, status) =>
  axios.patch(full_url + id + "/status?status=" + status, {}, options);

export { fetchItems, fetchItem, addItem, editItem, removeItem, statusItem };
