import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as pageApi from '../api/pages'
import { useSelector } from 'react-redux'

const EditPages = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const pagesTranslations = translations['system-pages']

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'title',
      type: 'text',
      title: pagesTranslations['edit-title'],
      validation: {
        required: true
      }
    },
    {
      name: 'menu',
      type: 'checkbox',
      title: pagesTranslations['edit-menu']
    },
    {
      name: 'content',
      type: 'tiny',
      title: pagesTranslations['edit-content']
    },
    {
      name: 'url',
      type: 'text',
      title: pagesTranslations['edit-url'],
      validation: {
        required: true
      }
    }
  ]
  return (
    <EditPage
      title={pagesTranslations['editpage-title']}
      subtitle={pagesTranslations['editpage-subtitle']}
      selectedData={selectedData} 
      addFunction={pageApi.addItem} 
      fetchFunction={pageApi.fetchItem} 
      editFunction={pageApi.editItem} 
      edit={edit} 
      url='pages' 
    />
  )
}

export default EditPages
