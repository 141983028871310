import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as juridicosApi from '../api/juridicos'
import { fetchItems } from '../api/categoriasJuridico'
import { useSelector } from 'react-redux'

const Juridicos = () => {
  const translations = useSelector(state => state.translations.translations)
  const juridicosTranslations = translations['system-juridicos']
  const [categorias, setCategorias] = useState({})

  useEffect(() => {
    fetchItems().then(res => {
      let categoriasOptions = {}
      // console.log(res.data)
      res.data.forEach(categoria => {
        categoriasOptions[categoria._id] = categoria.nome
      })
      setCategorias(categoriasOptions)
      
    })
  }, [])
  
    
    
  
  const columns = [
    
    {
      title: juridicosTranslations['manage-title'],
      field: "titulo",
      sorting: true
    },
    {
      title: juridicosTranslations['manage-category'],
      field: "categoria",
      center: true,
      sorting: categorias,
      render: rowData => rowData.categoria.nome
    
    }
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header title={juridicosTranslations['title']} subtitle={juridicosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'juridicos'}
            columns={columns}
            fetchFunction={juridicosApi.fetchItems}
            removeFunction={juridicosApi.removeItem}
            statusFunction={juridicosApi.statusItem}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  )
}

export default Juridicos
