import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as convenioCategoriaApi from "../api/convenio_categoria";

const ConveniosCategoria = () => {
  const translations = useSelector(
    (state) => state.translations.translations["system-convenios"]
  );

  const columns = [
    {
      title: translations["edit-title"],
      field: "title",
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={translations["title"] ?? "Lista Categoria Convenio"}
        subtitle={translations["subtitle"] ?? ""}
      >
        <i className="far fa-file-alt awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={convenioCategoriaApi.fetchItems}
            removeFunction={convenioCategoriaApi.removeItem}
            statusFunction={convenioCategoriaApi.statusItem}
            title={translations["title"] ?? "Editar Categoria"}
            url={"convenio_categoria"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default ConveniosCategoria;
