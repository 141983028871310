import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BigCard } from '@equipedigitalfloripa/ed-cms/components'
import { useSelector } from 'react-redux'
import styles from '../styles/IndexPage.module.scss'
import GraficoContato from '../components/GraficoContato'
import LittleCard from '../components/LittleCard'
import { toggleAssembleia, toggleRadioAoVivo } from '../api/socialmidia'
import axios from '../api/client'
import { fetchInfoPopup, fetchPopup, togglePopup } from '../api/imagemPrinc'

const Index = () => {
  const { system, index } = useSelector(state => state.translations.translations)
  const { user } = useSelector(state => state.auth)
  const [assembleiaAberta, setAssembleiaAberta] = useState(false)
  const [isRadioAoVivo, setIsRadioAoVivo] = useState(false)
  const [isPopUpAtivo, setIsPopUpAtivo] = useState(false);

  const handleToggleAssembleia = async () => {
    try {
      const res = await toggleAssembleia()
      const newData = res.data.assembleia
      // console.log(newData)
      setAssembleiaAberta(newData)
    }
    catch (e) {
      console.log(e);
    }

  }

  const handleRadioAoVivo = async () => {
    try {
      const res = await toggleRadioAoVivo()
      const newData = res.data.radioaovivo
      // console.log(newData)
      setIsRadioAoVivo(newData)
    }
    catch (e) {
      console.log(e);
    }

  }

  const handlePopUp = async () => {
    setIsPopUpAtivo(!isPopUpAtivo)
    await togglePopup()
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'socialmidia/611758143ac4e40e606c6d30', { withCredentials: true }).then(res => {
      setAssembleiaAberta(res.data.assembleia)
    })

    fetchInfoPopup().then(res => {
      if(res.data.status != "A"){
        setIsPopUpAtivo(false)
      }else 
        setIsPopUpAtivo(true)
    });
  }, [])


  return (
    <React.Fragment>
      <Container className={styles.title}>
        <h1>
          <span >{system['dash-hello']}</span>, {user.name}
        </h1>
      </Container>
      <div className="container page-content">
        <Row>
          <Col md="8" className="mb-4">
            <BigCard>
              <h2>{index.stats}</h2>
              <GraficoContato />
            </BigCard>
          </Col>
          <Col xs="12" sm="6" md="4">
            <LittleCard>
              <div className={styles.assembleia}>
                <div className={styles.left}>
                  <p>{index.assembly}</p>
                  <h3>{assembleiaAberta ? index.open : index.closed}</h3>
                </div>
                <label className={`${styles.switch} ${assembleiaAberta ? styles.on : ''}`} onClick={handleToggleAssembleia}>
                  <span className={styles.slider}></span>
                </label>
              </div>
            </LittleCard>
            <br />
            <LittleCard>
              <div className={`${styles.assembleia} ${styles.radio}`}>
                <div className={styles.left}>
                  <p>Rádio ao Vivo</p>
                  <h3>{isRadioAoVivo ? 'ao vivo' : 'offline'}</h3>
                </div>
                <label className={`${styles.switch} ${isRadioAoVivo ? styles.on : ''}`} onClick={handleRadioAoVivo}>
                  <span className={styles.slider}></span>
                </label>
              </div>
            </LittleCard>
            <LittleCard>
              <div className={`${styles.assembleia}`}>
                <div className={styles.left}>
                  <p>Popup</p>
                  <h3>{isPopUpAtivo ? 'ativo' : 'inativo'}</h3>
                </div>
                <label className={`${styles.switch} ${isPopUpAtivo ? styles.on : ''}`} onClick={handlePopUp}>
                  <span className={styles.slider}></span>
                </label>
              </div>
            </LittleCard>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Index
