import React, { useEffect, useState } from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
// import { EditPage } from '../components/EditPage'
import * as imagemApi from '../api/imagemPrinc'
import { useSelector } from 'react-redux'

const EditImagemPrinc = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const noticiasTranslations = translations['system-noticias']

 

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'type',
      type: 'select',
      title: "Tipo",
      selectOptions: [
        {value: 'whats', label: "WhatsApp"},
        {value: 'popup', label: "Popup"}
      ],
      validation: {
        required: true
      }
    },
    
    {
      name: 'file',
      type: 'dragdrop',
      title: "imagem",
      validation: {
        required: true
      }
    },
  ]
  return (
    <EditPage
      title={"Troca Imagem"}
      subtitle={"Aqui você faz a troca da imagem do whats/popup que aparece na pagina principal"}
      selectedData={selectedData} 
      addFunction={imagemApi.addFake}
      uploadFunction={imagemApi.uploadImagem}
      fetchFunction={imagemApi.fetchItem}
      edit={edit}
      url='Imagem'
    />
  )
}

export default EditImagemPrinc
