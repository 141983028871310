import axios from "./client";
import { serialize } from "@equipedigitalfloripa/ed-cms/api";

const url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3000/";
const full_url = url + "contatos/";

const options = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const fetchItems = (query) => {
  let url = full_url;
  if (query) url += "?" + serialize(query);
  return axios.get(url, options);
};
const fetchItem = (id) => axios.get(full_url + id, options);

const removeItem = (id) => axios.delete(full_url + id, options);

const getCount = (params = "") =>
  axios.get(full_url + "/count" + params, options);

export { fetchItems, fetchItem, removeItem, getCount };
