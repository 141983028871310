import React from "react";
import { useSelector } from "react-redux";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
//import { fetchCasino, editCasino, uploadCasino } from '../api/casinos'
import * as convenioCategoriaApi from "../api/convenio_categoria";

const EditConvenioCategoria = ({ edit }) => {
  const translations = useSelector(
    (state) => state.translations.translations["system-convenios"]
  );
  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "title",
      type: "text",
      title: translations["edit-title"],
    },
  ];
  return (
    // <EditPage selectedData={selectedData} apiFunctions={casinoApi} fetchFunction={fetchCasino} editFunction={editCasino} uploadFunction={uploadCasino} edit={true} url='casinos'/>
    <EditPage
      title={translations["editpage-title"] ?? "Editar Categoria"}
      subtitle={translations["editpage-subtitle"] ?? ""}
      selectedData={selectedData}
      addFunction={convenioCategoriaApi.addItem}
      fetchFunction={convenioCategoriaApi.fetchItem}
      editFunction={convenioCategoriaApi.editItem}
      edit={edit}
      url="convenio_categoria"
    />
  );
};

export default EditConvenioCategoria;
