import React, {useEffect} from 'react'
import { Form, Col } from 'react-bootstrap'
import moment from 'moment'
import DatePicker,{ registerLocale } from "react-datepicker";
import styled, { css, createGlobalStyle } from 'styled-components';
import pt from 'date-fns/locale/pt';
import "react-datepicker/dist/react-datepicker.css";

const Date = ({ name, value, fieldChanged, options }) => {
  useEffect(() => {
    registerLocale('pt-BR', pt)
  }, [])
  const DatePickerWrapperStyles = createGlobalStyle`
  .date_picker.full-width {
      z-index: 2;
  }
`;
  return (
    <Form.Row>
      <Col>
        <DatePicker
          wrapperClassName='date_picker full-width' 
          selected={value ? moment(value).toDate() : ''}
          onChange={date => fieldChanged(name, moment(date).toISOString())}
          className="form-control" 
          locale="pt-BR"
          timeFormat="p"
          timeIntervals={15}
          dateFormat={options.formatHora ? options.formatHora: 'dd/mm/yyyy' }
          showTimeInput = {options.formatHora}
          dropdownMode='scroll'
          />
      </Col>
    </Form.Row>
  )
}



export default Date
