import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormControl, Table, Button, Form } from "react-bootstrap";
import ReactLoading from "react-loading";
import {
  MdDelete,
  MdModeEdit,
  MdCheck,
  MdSearch,
  MdAdd,
  MdClose,
  MdNavigateBefore,
  MdNavigateNext,
  MdArrowDownward,
} from "react-icons/md";
import { Modal } from "../";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./tabela.scss";
import "./modal.scss";

export const Tabela = ({
  url,
  columns,
  fupdate,
  fetchFunction,
  removeFunction,
  statusFunction,
  addFunction,
  disableStatus,
  enableDatas,
  enableSearch,
  disableAdd,
  disablePagination,
  defaultSort,
}) => {
  const [data, setData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [statusFilter, setStatusFilter] = useState("A");
  const [categoriaFilter, setCategoriaFilter] = useState("");
  const [page, setPage] = useState(1);
  const [pageText, setPageText] = useState(1);
  const [search, setSearch] = useState("");
  const [sorts, setSorts] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxItems, setMaxItems] = useState(null);

  const translations = useSelector((state) => state.translations);
  const dtTranslations = translations.translations.datatable;

  //Roda quando iniciar ou quando um dos filtros mudarem
  useEffect(() => {
    //Verifica se é a primeira vez que está buscando os dados e se tem
    //uma ordem padrão, evita mútiplicas requisições à API
    if (data.length === 0 && defaultSort && Object.keys(sorts).length === 0) {
      setSorts(Object.assign({}, { name: defaultSort, order: "desc" }));
    } else {
      //Busca duas vezes, a primeira os dados e a segunda o numero
      //total de páginas
      fetchData();
      fetchData(false, true);
    }
    setPageText(page);
  }, [statusFilter, sorts, startDate, endDate, fupdate]);

  //Roda apenas quando mudar de página
  useEffect(() => {
    fetchData(true);

    setPageText(page);
  }, [page]);

  useEffect(async () => {
    //Url base do módulo no back-end
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const url =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_URL
        : "http://localhost:3266/";
    const full_url = url + "categorias/all";

    var response = await axios.get(full_url, options);
    setCategorias(response.data.categorias);
  }, []);

  let actionColumns = [
    {
      title: dtTranslations.alter,
    },
    {
      title: dtTranslations.delete,
    },
  ];
  if (!disableStatus)
    actionColumns.splice(1, 0, { title: dtTranslations.status });

  const allColumns = columns.concat(actionColumns);

  async function fetchData(loading = false, getTotal = false) {
    let options = {
      status: statusFilter,
      categoria: categoriaFilter,
      page: page,
      search: search,
    };
    if (sorts.name) {
      options.sort = sorts.name;
      options.order = sorts.order;
    }

    if (startDate) {
      options.start = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      options.end = moment(endDate).format("YYYY-MM-DD");
    }
    if (getTotal) options.getTotalItems = true;
    if (loading) setIsLoading(true);
    const response = await fetchFunction(options);
    !getTotal
      ? setData(response.data)
      : setMaxItems(parseInt(response.data.length));
    setIsLoading(false);
  }

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoriaFilter(e.target.value);
  };

  const handlePageChange = (e) => {
    if (e.target.validity.valid) {
      setPageText(e.target.value);
    }
  };

  const handleSubmitPage = (e) => {
    e.preventDefault();
    setPage(pageText);
  };

  const handleNextPage = () => {
    setPage((curr) => (curr += 1));
  };

  const handlePrevPage = () => {
    if (page - 1 > 0) {
      setPage((curr) => (curr -= 1));
    }
  };

  const handleSort = (field) => {
    if (sorts.name !== field) {
      setSorts(Object.assign({}, { name: field, order: "desc" }));
    } else {
      setSorts((current) => {
        const newObj = {
          name: current.name,
          order: current.order === "asc" ? "desc" : "asc",
        };
        return Object.assign({}, newObj);
      });
    }
  };

  const handleStatus = (item) => {
    const newStatus = item.status == "A" ? "D" : "A";
    statusFunction(item._id, newStatus).then((res) => {
      if (res.status && res.status == 200) {
        setData((current) =>
          [...current].map((object) => {
            if (object._id === item._id) {
              return {
                ...object,
                status: newStatus,
              };
            } else {
              return object;
            }
          })
        );
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleUpdateData = () => {
    fetchData();
  };

  const handleSelected = (item) => setSelected(item);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (item) => {
    handleSelected(item);
    handleShow();
  };
  return (
    <>
      <div className="ed-tabela__acoes">
        {enableSearch ? (
          <Form className="ed-tabela__pesquisa" onSubmit={handleSearchSubmit}>
            <FormControl
              type="text"
              placeholder={dtTranslations["toolbar-searchPlaceholder"]}
              value={search}
              onChange={handleSearchChange}
            />
            {categorias.length > 0 && (
              <select
                style={{
                  width: "125px",
                }}
                name="categoria"
                className="form-control ml-2"
                value={categoriaFilter}
                onChange={handleCategoriaChange}
              >
                <option value="">Categorias</option>
                {categorias.map((categoria, index) => {
                  return (
                    <option data-index={index} value={categoria.id}>
                      {categoria.nome}
                    </option>
                  );
                })}
              </select>
            )}
            <Button className="ml-1" type="submit">
              <MdSearch size="1rem" className="text-light" />
            </Button>
          </Form>
        ) : (
          <div></div>
        )}
        <div className="ed-tabela__filtros">
          {enableDatas && (
            <div className="ed-tabela__datas">
              <div>
                <p>{dtTranslations.filter_date}</p>
                <DatePicker
                  selected={startDate ? moment(startDate).toDate() : ""}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setStartDate(moment(date).toISOString())}
                  className="form-control data"
                  placeholderText={dtTranslations.date_start}
                />
              </div>
              <div>
                <p></p>
                <DatePicker
                  selected={endDate ? moment(endDate).toDate() : ""}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setEndDate(moment(date).toISOString())}
                  className="form-control data"
                  placeholderText={dtTranslations.date_end}
                />
              </div>
            </div>
          )}
          {!disableStatus && (
            <div className="ed-tabela__status">
              <p>{dtTranslations.status}</p>
              <select
                className="form-control"
                value={statusFilter}
                onChange={handleStatusChange}
              >
                <option value="all">{dtTranslations.all}</option>
                <option value="A">{dtTranslations.active}</option>
                <option value="D">{dtTranslations.inactive}</option>
              </select>
            </div>
          )}
          {addFunction ? (
            <div onClick={addFunction} className="add-button">
              <p>{dtTranslations.add}</p>
              <MdAdd size="2rem" />
            </div>
          ) : (
            !disableAdd && (
              <Link to={"/add/" + url} className="add-button">
                <p>{dtTranslations.add}</p>
                <MdAdd size="2rem" />
              </Link>
            )
          )}
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          <ReactLoading color="#0586D7" type="spin" />
        </div>
      ) : data.length > 0 ? (
        <>
          <Table responsive bordered className="ed-tabela">
            <thead>
              <tr>
                {allColumns.map((column, index) => (
                  <th
                    key={index}
                    style={{
                      minWidth: column.sorting
                        ? `calc(${column.title.length}ch + 2em)`
                        : "",
                    }}
                    className="ed-tabela__sort clickable"
                    onClick={() => {
                      if (column.sorting) return handleSort(column.field);
                    }}
                  >
                    {column.title}
                    {column.sorting && (
                      <div
                        className={
                          "ed-tabela__sort__icon " +
                          (sorts.name === column.field ? "active" : "")
                        }
                      >
                        <MdArrowDownward
                          className={
                            sorts.name === column.field && sorts.order == "asc"
                              ? "rotate"
                              : ""
                          }
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map(
                (item) =>
                  item.status !== "I" && (
                    <tr key={item._id}>
                      {columns.map((column, index) => (
                        <td
                          key={index}
                          className={column.center ? "center texto" : "texto"}
                        >
                          {column.render
                            ? column.render(item)
                            : item[column.field]}
                        </td>
                      ))}
                      <td className="center action">
                        <Link to={"/edit/" + url + "/" + item._id}>
                          <MdModeEdit size="1.5rem" />
                          <p className="hide-mobile">
                            {dtTranslations.alter_data}
                          </p>
                        </Link>
                      </td>
                      {!disableStatus && (
                        <td className="center action">
                          <div
                            className="clickable"
                            onClick={() => handleStatus(item)}
                          >
                            {item.status == "A" ? (
                              <>
                                <p className="hide-mobile">
                                  {dtTranslations.active}
                                </p>
                                <MdCheck
                                  size="1.5rem"
                                  className={"circulo ativo"}
                                />
                                <p className="hide-mobile">
                                  {dtTranslations.click_for}{" "}
                                  <strong>{dtTranslations.DEACTIVATE}</strong>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="hide-mobile">
                                  {dtTranslations.inactive}
                                </p>
                                <MdClose
                                  size="1.5rem"
                                  className={"circulo inativo"}
                                />
                                <p className="hide-mobile">
                                  {dtTranslations.click_for}{" "}
                                  <strong>{dtTranslations.ACTIVATE}</strong>
                                </p>
                              </>
                            )}
                          </div>
                        </td>
                      )}
                      <td className="center action">
                        <div
                          onClick={() => handleDelete(item)}
                          className="clickable"
                        >
                          <p className="hide-mobile">
                            {dtTranslations.click_for}{" "}
                            <strong>{dtTranslations.DELETE}</strong>
                          </p>
                          <MdDelete size="1.5rem" />
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="ed-tabela__notFound">
          <h2>{dtTranslations.notfound}</h2>
        </div>
      )}
      {!disablePagination && (
        <div className="ed-tabela__pagination">
          {page - 1 > 0 && (
            <MdNavigateBefore
              size="2rem"
              onClick={handlePrevPage}
              className="clickable"
            />
          )}
          <Form onSubmit={handleSubmitPage}>
            <FormControl
              type="text"
              value={pageText}
              pattern="[0-9]*"
              onChange={handlePageChange}
            />
          </Form>

          {(page - 1) * 10 + data.length - maxItems < 0 && (
            <MdNavigateNext
              size="2rem"
              onClick={handleNextPage}
              className="clickable"
            />
          )}
        </div>
      )}
      <Modal
        type="warning"
        show={show}
        title={dtTranslations.delete_item}
        body={dtTranslations.delete_sure}
        handleClose={handleClose}
        confirmFunction={() => {
          removeFunction(selected._id)
            .then((res) => {
              if (res.status === 200) {
                handleUpdateData();
                handleClose();
              } else {
                throw new Error("could not delete");
              }
            })
            .catch((e) => {
              handleClose();
              console.log(e);
            });
        }}
        cancelFunction={() => {
          handleSelected({});
        }}
      />
    </>
  );
};

export default Tabela;
