import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as categoriasApi from '../api/categorias'
import { useSelector } from 'react-redux'

const Categoria = () => {
  const translations = useSelector(state => state.translations.translations)
  const categoriasTranslations = translations['system-categorias']

  const columns = [
    {
      title: categoriasTranslations['manage-title'],
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header title={categoriasTranslations['title']} subtitle={categoriasTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={categoriasApi.fetchItems}
            removeFunction={categoriasApi.removeItem}
            statusFunction={categoriasApi.statusItem}
            title={categoriasTranslations['title']}
            url={'categorias'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Categoria
