//Servicos para configurar todas as rotas de request do módulo

//Importa a biblioteca Axios com um interceptor customizado
import axios from './client'
import { serialize } from '@equipedigitalfloripa/ed-cms/api'
//Url base do módulo no back-end
const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3000/'
const full_url = url + 'servicos_conf/'

//AVISO IMPORTANTE!
//É necessário incluir os credentials em cada request para que o express possa
//adicionar os cookies necessários para uso 
const options = {
  withCredentials: true
}

//Configuração adicional para upload de servicos
const optionsUpload = {
  withCredentials: true
}

//Pega todos os items
const fetchItems = (query) => {
  let url = full_url
  if (query) url += ('?' + serialize(query))
  return axios.get(url, options)
}

//Pega um Único item
const fetchItem = (id) => axios.get(full_url, options)

//Adiciona item
const addItem = (noticia) => axios.post(full_url, noticia, options)

//Edita um item
const editItem = (id, noticia) => axios.post(full_url, noticia, options)

//Remove um item
const removeItem = (id) => axios.delete(full_url, options)

//Atualizar status de um item
const statusItem = (id, status) => axios.patch(full_url + '/status?status=' + status, {}, options)

//Faz o upload de imagem (única)
const uploadServicos = (id, formData) => axios.post(full_url + 'imagem', formData, optionsUpload).then(() => {
  alert('Imagens enviadas')
})

//Pega imagem de noticia
const getFileServicos = (id) => full_url + 'imagem'


export { fetchItems, fetchItem, addItem, editItem, removeItem, statusItem, uploadServicos, getFileServicos }