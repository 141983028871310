import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { auth, translations, darkTheme } from "@equipedigitalfloripa/ed-cms/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeConfig = () => {
  const store = createStore(
    combineReducers({
      auth,
      translations,
      darkTheme,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};

export default storeConfig;
