import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BigCard, Header } from "@equipedigitalfloripa/ed-cms/components";
import { Tabela } from "../components/Tabela/Tabela";
import * as convenioApi from "../api/convenio";

const Convenios = () => {
  const translations = useSelector(
    (state) => state.translations.translations["system-convenios"]
  );
  async function teste() {
    console.log(await convenioApi.fetchItems({getTotalItems:true}))
  }
  teste()
  const columns = [
    {
      title: "Imagem Convênio",
      field: "_id",
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: (rowData) => (
        <img
          className="noticia-img"
          src={convenioApi.getImgConvenio(rowData._id)}
          alt=""
        />
      ),
    },
    {
      title: translations["edit-title"],
      field: "title",
    },
    {
      title: translations["edit-categoria"] ?? "Categoria",
      field: "categoria",
    },
  ];

  return (
    <React.Fragment>
      <Header title={translations["title"]} subtitle={translations["subtitle"]}>
        <i className="far fa-file-alt awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={convenioApi.fetchItems}
            removeFunction={convenioApi.removeItem}
            statusFunction={convenioApi.statusItem}
            title={translations["title"]}
            url={"convenios"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Convenios;
