import React from 'react'
import Select from 'react-select';
import { Form } from 'react-bootstrap'

const MultiSelect = ({ selectOptions, name, value = [], multiChanged, valid }) => {
  return (
    <Select
      options={selectOptions}
      isMulti
      name={name}
      value={value}
      isInvalid={true}
      className={`${'multiSelect'} ${(valid || valid === undefined ? '' : 'is-invalid')}`}
      classNamePrefix={'multiSelect'}
      onChange={(e) => multiChanged(e, name)}
    />

  )
}

export default MultiSelect
