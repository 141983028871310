import React from 'react'
import { Form } from 'react-bootstrap'

const Select = ({ name, options, selectOptions, defaultVal, value, fieldChanged, valid }) => {

  return (
    <Form.Control
      as="select"
      onChange={e => fieldChanged(name, e.target.value)}
      value={value}
      isInvalid={valid === undefined ? false : !valid}
      isValid={valid === undefined ? false : valid}
    >
      {<option key={'empty'} value=''>{defaultVal}</option>}
      {selectOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </Form.Control>

  )
}

export default Select
