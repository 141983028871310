import React from 'react'
import { Card } from 'react-bootstrap'
import "./bigcard.scss"

export const BigCard = ({children}) => {
  return (
    <Card className="big-card">
      <Card.Body >
        {children}
      </Card.Body>
    </Card>
  )
}
