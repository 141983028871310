import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as arquivoApi from '../api/arquivos'
import { useSelector } from 'react-redux'

const EditArquivo = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const arquivosTranslations = translations['system-arquivos']

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'title',
      type: 'text',
      title: arquivosTranslations['edit-title'],
      validation: {
        required: true
      }
    },
    {
      name: 'file',
      type: 'file',
      title: arquivosTranslations['edit-arquivo'],
      validation: {
        required: true
      }
    }
  ]
  return (
    <EditPage
      title={edit ? arquivosTranslations['editpage-title'] : arquivosTranslations['addpage-title']}
      subtitle={edit ? arquivosTranslations['editpage-subtitle'] : arquivosTranslations['addpage-subtitle']}
      selectedData={selectedData}
      addFunction={arquivoApi.addItem}
      fetchFunction={arquivoApi.fetchItem}
      editFunction={arquivoApi.editItem}
      uploadFunction={arquivoApi.uploadArquivo}
      edit={edit}
      url='arquivos' />
  )
}

export default EditArquivo
