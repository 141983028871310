import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as categoriaJuridicoApi from '../api/categoriasJuridico'
import { useSelector } from 'react-redux'

const EditCategoriasJuridico = ({ edit }) => {
    const translations = useSelector(state => state.translations.translations)
    const categoriasTranslations = translations['system-categoriasJuridico']

    const selectedData = [
        {
            name: '_id',
            type: 'hidden',
            title: 'Id'
        },
        {
            name: 'nome',
            type: 'text',
            title: categoriasTranslations['edit-title'],
            validation: {
                required: true
            }
        }
    ]
    return (
        <EditPage 
            selectedData={selectedData} 
            addFunction={categoriaJuridicoApi.addItem} 
            fetchFunction={categoriaJuridicoApi.fetchItem} 
            editFunction={categoriaJuridicoApi.editItem} 
            edit={edit} 
            url='categorias_Juridico' 
        />
    )
}

export default EditCategoriasJuridico
