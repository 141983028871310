import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as sliderApi from '../api/slider'
import { useSelector } from 'react-redux'

const Slider = () => {
  const translations = useSelector(state => state.translations.translations)
  const sliderTranslations = translations['system-slider']

  const columns = [
    {
      title: sliderTranslations['title'],
      field: "title",
    },
    {
      title: sliderTranslations['slider'],
      field: 'slider_background',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 30,
      },
      render: rowData => <img className="cassino-img" src={sliderApi.getFileSlider(rowData._id)} style={{ maxWidth: 50, maxHeight: 40 }} alt="" />
    },
  ];

  return (
    <>
      <Header title={sliderTranslations['manage-title']} subtitle={sliderTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={sliderApi.fetchItems}
            removeFunction={sliderApi.removeItem}
            statusFunction={sliderApi.statusItem}
            title={sliderTranslations['manage-title']}
            url={'slider'}
          />
        </BigCard>
      </Container>
    </>
  )
}

export default Slider
